import { faChevronDown, faInfinity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

export interface DropdownProps {
  title: string;
  type: React.FunctionComponent;
  partner?: boolean;
  verified?: boolean;
  children: any;
}

const Dropdown: React.FunctionComponent<DropdownProps> = (props) => {
  const dropdown = useSelector((s: Store.AppState) => s.ui.openDropdown);
  const ispartner = props.partner

  return (
    <div className="dropdown bg-bg-secondary w-full pt-3">
      <div className="flex justify-between items-center">
        <h1 className="flex-grow font-bold pl-2"> 
        {
        (props.partner === true) &&  
        <FontAwesomeIcon icon={faInfinity} title="HamChat Partner Server" color = '#f24040'/>
        } 
        {
        (props.verifed === true) &&  
        <FontAwesomeIcon icon={faInfinity} title="HamChat Partner Server" color = '#f24040'/>
        } 
            <span> </span>{props.title}</h1>
        <FontAwesomeIcon icon={faChevronDown}/>
      </div>
      {
        (dropdown === props.type.name) &&
        <div className="absolute bg-bg-floating rounded w-56 p-2 mt-5 z-10">
          {props.children}
        </div>
      }
    </div>
  );
}

export default Dropdown;